import jquery from "/assets/scripts/jquery.js";
import extra from "/assets/scripts/extra.js";
import collection from "/assets/scripts/collection.js";
import cookies from "/assets/scripts/cookies.js";
import forms from "/assets/scripts/forms.js";
import animation from "/assets/scripts/animation.js";

document.addEventListener("DOMContentLoaded", function () {
  jquery();
  extra();
  cookies();
  forms();
  animation();
  collection();
});
