/* Tiny Slider --------------*/
import { tns } from "../../node_modules/tiny-slider/src/tiny-slider";
// import { VenoBox } from 'venobox';
// import VenoBox from '../../node_modules/src/venobox.esm.js';
// import VenoBox from './src/venobox.esm.js'

import { gsap } from "gsap";

export default () => {
  let mm = gsap.matchMedia();

  //------------------------------------------------------//
  // On browser 📜 scroll full window height
  //------------------------------------------------------//
  window.onscroll = function (ev) {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      // you're at the bottom of the page
      document.body.classList.add("showScrolled");
    } else {
      document.body.classList.remove("showScrolled");
    }
  };
  // END On browser 📜 scroll full window height  -------------------------------------//

  document.querySelectorAll(".ACF-slider").forEach(function () {
    var slider = tns({
      container: ".ACF-slider",
      autoplay: true,
      autoplayButton: false,
      controls: false,
      controlsPosition: "bottom",
      controlsText: ["<", ">"],
      nav: true,
      navPosition: "bottom",
      lazyload: false,
      arrowKeys: true,
    });
  });
  /* END Tiny Slider --------------*/

  new VenoBox({
    selector: ".venobox",
    // numeration: true,
    infinigall: true,
    // share: true,
    spinner: "rotating-plane",
  });
  //--------------------------------//
  // Slide menu
  //--------------------------------//
  document.querySelectorAll(".alloy-menu-slide").forEach((container) => {
    // Menu slide animation
    let isAnimating = false;
    let isToggled = false;
    const navigationSlide = container;
    const toggle = container.dataset.toggle;
    const q = gsap.utils.selector(navigationSlide);
    const menuSlideAnimation = gsap.timeline({
      paused: true,
      reversed: true,
      defaults: { duration: 0.2, ease: "power4.in" },
      // onStart: () => (isAnimating = true),
      onComplete: () => {
        isAnimating = false;
      },
      onReverseComplete: () => (isAnimating = false),
    });

    if (toggle === "menu-jam-toggle") {
      // JAM sidebar animation
      gsap.set(q("li span"), { autoAlpha: 1 });
      menuSlideAnimation.to(navigationSlide, {
        width: "auto",
        x: 0,
        duration: 0.2,
      });
      menuSlideAnimation.from(q("li span"), {
        x: -20,
        opacity: 0,
        stagger: { amount: 0.3 },
      });
    } else {
      // Other slide navigation animation
      gsap.set(navigationSlide, { xPercent: 100 });
      // Animation
      menuSlideAnimation.set(navigationSlide, { autoAlpha: 1 });
      menuSlideAnimation.to(navigationSlide, { xPercent: 0, duration: 0.2 });
      menuSlideAnimation.from(q("ul li"), {
        x: 100,
        opacity: 0,
        stagger: { amount: 0.3 },
      });
      menuSlideAnimation.from(q(".extra > *"), {
        opacity: 0,
        stagger: { amount: 0.3 },
      });
    }
    // On 🐭  hover
    navigationSlide.addEventListener("mouseenter", function () {
      // menuSlideAnimation.reversed(!menuSlideAnimation.reversed()).timeScale(1); // Speel aimatie
      if (isToggled) {
        return;
      }
      if (isAnimating) {
        return;
      }
      animateMenu("open");
    });
    // On 🐭  leave
    navigationSlide.addEventListener("mouseleave", function () {
      if (isToggled) {
        return;
      }
      if (isAnimating) {
        return;
      }

      animateMenu("close");
    });
    // Menu toggle setup
    let tlMenuToggleAll = []; // Collect all menu buttons;
    const menuToggle = document.querySelectorAll(`.${toggle}`);
    // Animation function
    function menuToggleAnimation(target) {
      const q = gsap.utils.selector(target);
      // Menu toggle animation
      const tl = gsap.timeline({
        paused: true,
        reversed: true,
        defaults: { duration: 0.3, ease: "power3.in" },
      });
      // ---- Rotate animation ----
      tl.to(q(".anim"), { y: 0 });
      tl.to(q(".anim"), {
        duration: 0.45,
        rotate: gsap.utils.wrap([45 * 3, 45 * 5]),
      });
      tl.to(q(".text"), { duration: 0.1, opacity: 0 }, "<");
      tlMenuToggleAll.push(tl); // Capture all timelines
      return tl;
    }
    // Animate menu
    function animateMenu(direction) {
      // isAnimating = true;
      document.body.classList.toggle(`show-${toggle}`);
      direction === "open"
        ? menuSlideAnimation.play()
        : menuSlideAnimation.reverse();
    }
    // 👆 Click logic function
    function menuToggleClick(target) {
      // Setup aria roles
      target.setAttribute("aria-haspopup", "true");
      target.setAttribute("aria-expanded", "false");
      target.setAttribute("aria-controls", "navigation-slide");

      // Click logic
      target.addEventListener("click", (e) => {
        e.preventDefault();
        isToggled = !isToggled;
        // animateMenu();
        tlMenuToggleAll.forEach((timeline, index) => {
          timeline.reversed() ? timeline.play() : timeline.reverse();
          // ♿️ Accessibility logic
          menuToggle[index].setAttribute(
            "aria-expanded",
            menuToggle[index].getAttribute("aria-expanded") == "false"
              ? "true"
              : "false",
          );
        });
      });
    }
    menuToggle.forEach((item) => {
      const q = gsap.utils.selector(item);
      const offset = item.getBoundingClientRect().height * 0.2;
      // Ready function
      function ready() {
        // Add animation to button
        if (item.tagName === "BUTTON") {
          menuToggleAnimation(item);
        }
        // 👆 Add click logic
        menuToggleClick(item);
      }
      // Page load animation
      const tl = gsap.timeline({
        onComplete: () => ready(),
      });
      // Animate only if item is a button
      if (item.tagName === "BUTTON") {
        tl.to(q(".anim"), {
          y: gsap.utils.wrap([offset, -offset]),
          duration: 0.15,
          ease: "power4.in",
        });
        tl.from(q(".text"), { opacity: 0 });
      }
    });
    // END Menu slide animation --------------//

    // END JAM Sidebar --------------//
  });
};
